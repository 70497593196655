import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

// Connects to data-controller="fluid--tooltip"
export default class extends Controller {
  connect() {
    tippy(this.element, {
      content: this.content,
      placement: this.placement
    });
  }

  get content() {
    return this.data.get('content') || 'Missing data-content for tooltip'
  }

  get placement() {
    return this.data.get('placement') || 'top'
  }
}
