import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fluid--modal"
export default class extends Controller {
  connect() {
    this.escapeListener = (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    }
    document.addEventListener('keydown', this.escapeListener)
  }

  disconnect() {
    document.removeEventListener('keydown', this.escapeListener)
  }

  show(event) {
    const modalId = event.currentTarget.getAttribute('data-modal-target')
    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.remove('hidden')
    }
  }

  close(event) {
    const modal = event ? event.currentTarget.closest('.fluid-modal-container') : document.querySelector('.fluid-modal-container:not(.hidden)')
    if (modal) {
      modal.classList.add('hidden')
    }
  }
}
